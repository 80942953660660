import * as React from 'react'
import { graphql } from 'gatsby'
import EntriesPage from '../components/entries'

const ProjectsPage = ({ data }) => (<EntriesPage title="Personal Projects" entries={data.allFile.nodes} />)

export const query = graphql`
{
    allFile(
      filter: {sourceInstanceName: {eq: "projects"}}
      sort: {order: DESC, fields: childrenMdx___frontmatter___order}
    ) {
      nodes {
        childMdx {
          body
          slug
          frontmatter {
            title
            url
          }
        }
      }
    }
  }  
`;

export default ProjectsPage

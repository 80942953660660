import * as React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from './layout'
import { entry } from '../styles/layout.module.css'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Seo from './seo'

const EntriesPage = ({ title, entries, extraHandler }) => (
    <Layout>
        <Seo title={title} />
        <h1 className="display-5">{title}</h1>
        {
            entries.map(p => {
                let titleEl = <h2 className="card-title">{p.childMdx.frontmatter.title}</h2>;
                if (p.childMdx.frontmatter.url) {
                    titleEl = <OutboundLink href={p.childMdx.frontmatter.url}>{titleEl}</OutboundLink>;
                }
                return (
                    <article className={"m-3 card entry " + entry} key={p.childMdx.slug}>
                        <div className="card-body">
                            {titleEl}
                            {extraHandler ? extraHandler(p) : null}
                            <MDXRenderer className="card-text">{p.childMdx.body}</MDXRenderer>
                        </div>
                    </article>
                );
            })
        }
    </Layout>
);

export default EntriesPage;
